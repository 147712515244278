a {
    color: #1068CE;
}
body .table td, .table th {
    vertical-align: middle;
}
table th {
    color: #fff;
    background: #1068CE;
}
h4.card-title {
    color: #000;
}

tr button.dropdown-toggle {
    background: #1068CE;
    color: #fff;
}

body .page-item.active .page-link {
    background: #1068CE;
    color: #fff;
    border-color: #1068CE;
}
body .page-item .page-link {
    cursor: pointer;
}

table tr[data-href]:hover {
    background: #f0f0f0;
    cursor: pointer;
}

body[data-layout="detached"] #layout-wrapper::before {
    background: -webkit-gradient(linear, left top, right top, from(#1068CE), to(#253C7E)) !important;
    background: linear-gradient(to right, #1068CE, #253C7E) !important;
    -webkit-box-shadow: 1px 0 7px 0 rgba(0, 0, 0, 0.5);
    box-shadow: 1px 0 7px 0 rgba(0, 0, 0, 0.5);
}

.icons {
    margin: 20px 0;
    text-align: left;
    color: #adb5bd;
}
.icons a {
    display: inline-block;
    width: auto;
    text-align: center;
    margin-right: 20px;
}
.icons i {
    display: block;
    font-size: 24px;
    margin-bottom: 16px;
    color: #adb5bd;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
}

.auctionFontSize {
    font-size: 22px;
}

.icons a:hover i {
    color: #cda73a;
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
}
#side-menu i,
#side-menu a,
.paginate_button.page-item.active a,
.breadcrumb-item a {
    color: #1068CE !important;
}
#side-menu li:hover a,
#side-menu li:hover i {
    color: #000000 !important;
}

.ribbon-wrapper-files {
    background-color: #858585;
    color: #fff;
    width: 100%;
    position: relative;
    padding: 50px 15px 15px 15px;
}
.ribbon-espd {
    font-weight: 400;
    background: #cda73a;
}
.ribbon {
    padding: 0 20px;
    height: 30px;
    line-height: 30px;
    clear: left;
    position: absolute;
    top: 12px;
    left: -2px;
    color: #ffffff;
}
.dropzone {
    min-height: 150px !important;
    border: 1px dashed #b1b8bb !important;
    background: white !important;
    padding: 20px 20px !important;
}
.btn-success {
    background-color: #CDA73A;
}

.btn-success:hover {
    background-color: #dfb12d;
}
#accordion .card-header {
    cursor: pointer;
}

#side-menu a.active {
    color: #1068CE !important;
}

.table {
    color: #000;
}
body .footer {
    min-height: 120px;
    height: auto;
    position: relative;
}
body[data-layout="detached"] .main-content {
    padding-bottom: 160px !important;
}
body .paginate_button.page-item.active a {
    color: #fff !important;
}
footer .col-sm-3 {

}
.edrazbeLogo .st0,
.edrazbeLogo .st1{
    fill: #ffffff !important;
}

.logo span.logo-sm {
    width: 30px;
}
.btn-custom {
    background-color: #1068CE;
    border-color: #1068CE;
    color: #fff;
}
body .btn:hover{
    color: #000;
}
.card-header.cancel {
    background-color: #c04040;
}
.card-header.cancel h6 {
    color: #fff;
}

.displayNone {
    display: none;
}
.height-auto-form-control {
    height: auto !important;
    min-height: calc(1.5em + 0.94rem + 2px);
}
h6 {
    font-size: inherit;
}

body .card-title {
    font-size: inherit;
}

body.grayscale {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    filter: grayscale(100%);
}
body .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: none;
}

.ant-picker-now {
    display: none !important;
}
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control::before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control::before {
    height: 14px;
    width: 14px;
    display: inline-block;
    color: #fff;
    border: 2px solid #fff;
    border-radius: 14px;
    box-shadow: 0 0 3px #444;
    box-sizing: content-box;
    text-align: center;
    text-indent: 0 !important;
    font-family: "Courier New",Courier,monospace;
    line-height: 14px;
    content: "+";
    background-color: #1068ce;
}

body .react-tiny-popover-container {
    z-index: 6000;
    width: 200px;
    color: #fff;
    margin-left: 20px;
}
body .react-tiny-popover-container a{
    color: #F5AF5A;
}

.close-sidebar {
    width: 100% !important;
}

.popover-close-btn {
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
}

.popover-close-btn:hover {
    background: #fff;
    color: rgb(208, 0, 0) !important;
}

.btn-login {
    font-weight: 600;
    color: #253C7E;
    background-color: #fff;;
}

.header-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@media (max-width: 1380px) {
    .header-logo {
        position: relative;
        left: 0;
        transform: none;
    }
}

@media (max-width: 1180px) {
    .header-logo {
        display: none;
    }
}

.diag-card {
    width: 500px;
    max-width: 100%;
    color: #1068CE;
    margin-bottom: 40px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 3px;
}

.diag-info {
    margin-bottom: 20px;
}

.diag-title {
    text-align: center;
    width: 100%;
    padding: 10px;
    background: linear-gradient(to right, #1068CE, #253C7E);
    color: #fff;
    margin-bottom: 0px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.diag-body {
    padding: 15px;
    color: #000;
}

.diag-body > div {
    display: flex;
    justify-content: space-between;
}

.diag-success {
    color: #19991d;
}
.diag-error {
    color: red;
}

.btn-not-picked {
    background-color: #cccccc;
}

.infinite-scroll-component {
    overflow: visible !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.infinite-scroll-component::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .infinite-scroll-component {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

.btn:disabled {
    background-color: #979797;
    border-color: #979797;
}

.btn:disabled:hover {
    color: #fff;
}