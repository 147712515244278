.bigA {
    color: #fff;
    background: none;
    border: none;
    font-weight: bold;
    font-size: 1.5rem;
}

.smallA {
    color: #fff;
    background: none;
    border: none;
    font-weight: bold;
    font-size: 1.2rem;
}

.resetA {
    color: #fff;
    background: none;
    border: none;
    font-weight: bold;
    font-size: 0.8rem;
}

.disabled {
    font-size: 1.5rem;
}

