#datatable {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}
.Icons {
  margin: 20px 0;
  text-align: left;
  color: #adb5bd;
}
.Icons span {
    display: inline-block;
    width: auto;
    text-align: center;
    cursor: pointer;
}
.Icons i {
  display: block;
  font-size: 24px;
  margin-bottom: 16px;
  color: #adb5bd;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.Icons span:hover i {
  color: #1068CE;
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}
table tr[data-id]:hover {
  background: #f0f0f0;
  cursor: pointer;
}
