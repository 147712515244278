.fileUploadButton {
    position: relative;
}

.fileImport {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    opacity: 0;
}
