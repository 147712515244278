.progress {
    height: 1.5rem;
}
#files {
    min-height: 320px;
}
.preview-img {
    width: 64px;
    height: 64px;
}
form.active {
    border-color: red !important;
}
.progress {
    height: 38px;
}
.dm-uploader {
    border: 1px dashed #9e9e9e;
}
.dm-uploader.active {
    border-color: red;
    border-style: solid;
}
.h-100 {
    height: 100% !important;
    /*border: 1px solid rgba(0,0,0,.125);*/
}

#drag-and-drop-zone h3 {
    text-align: center;
    font-size: 1.5vw;
}
#drag-and-drop-zone .btn-block {
    width: 60%;
    margin-left: 20%;
}
.media-body {
    color: #1D4D9E;
}
.media-body strong{
    color: rgb(107, 111, 130);
}
.card .card-content #files  p {
    padding-bottom: 0px;
}
#files li {
    border-bottom: 1px dashed #9e9e9e;
    margin-bottom: 10px;
}
#files li.empty {
    border-bottom: none;
}
.progress{display:-webkit-box;display:-ms-flexbox;display:flex;height:2rem;overflow:hidden;font-size:.75rem;background-color:#e9ecef;border-radius:.25rem}

.progress-bar{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;color:#fff;text-align:center;background-color:#1D4D9E;transition:width .6s ease}
.progress-bar-striped{background-image:linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);background-size:1rem 1rem}
.progress-bar-animated{-webkit-animation:progress-bar-stripes 1s linear infinite;animation:progress-bar-stripes 1s linear infinite}

.bg-success{background-color:#1D4D9E!important}

.delete_this_file {
    display: none;
}

.cursor {
    cursor: pointer;
}
