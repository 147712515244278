body.darkMode {
    background-color: #17212f;
}

body.darkMode .card {
    background-color: #1e2938;

}

body.darkMode .card {
    box-shadow: 5px 0.75rem 1.5rem rgba(255,255,255,0.2);
}

body.darkMode .vertical-menu {
    background: #1e2938;
}

body.darkMode #side-menu i,
body.darkMode #side-menu a,
body.darkMode .paginate_button.page-item.active a,
body.darkMode .breadcrumb-item a,
body.darkMode #side-menu a{
    color: #7f879c !important;
}

body.darkMode .bidElements.mineBid {
    background: #32445D;
}

body.darkMode li.menu-title span {
    border: 0.0625rem solid #7f879c;
    border-left: 0.25rem solid #7f879c;
    padding: 0.75rem 0 0.75rem 1.5625rem;
    color: #7f879c;
}

body.darkMode footer {
    background-color: #273344;
}

body.darkMode .text-dark {
    color: #fff !important;
}

body.darkMode .card-title {
    color: #fff !important;
}

body.darkMode .ant-picker,
body.darkMode .custom-select,
body.darkMode .form-control {
    color: #fff;
    background-color: #2b384a;
    border: 1px solid #364458;
}
body.darkMode input.form-control.mandatory::placeholder {
    color:  #F2695F;
    opacity: 1;
}
body.darkMode .custom-select.mandatory {
    color: #F2695F;
}

body.darkMode .ant-picker input {
    color: #fff;
}
body.darkMode .nav-tabs .nav-link.active,
body.darkMode .nav-tabs .nav-item.show .nav-link {
    color: #e0e0e0;
    background-color: #273344;
    border-color: #364458 #364458 #273344;
}

body.darkMode .table {
    color: #fff;
}
body.darkMode table tr[data-id]:hover {
    background: #17212f;
}

body.darkMode .page-item.disabled .page-link {
    color: #ced4da;
    background-color: #2b384a;
    border-color: #ced4da;
}

body.darkMode .card-header {
    background-color: #2b384a;
    border-bottom: 0 solid #2b384a;
}
body.darkMode .h1, body.darkMode .h2, body.darkMode .h3, body.darkMode .h4, body.darkMode .h5, body.darkMode .h6, body.darkMode h1, body.darkMode h2, body.darkMode h3, body.darkMode h4, body.darkMode h5, body.darkMode h6 {
    color: #fff;
}
body.darkMode .table-bordered {
    border: 1px solid #000;
}
body.darkMode .table-bordered th, body.darkMode .table-bordered td {
    border: 1px solid #000;
}
body.darkMode .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #000;
}

body.darkMode table.table .thead-light th {
    color: #fff;
    background: #1068CE;
}

body.darkMode .subjectList tr:hover td{
    background: #17212F;
}

body.darkMode .page-link {
    background-color: #2B384A;
}
body.darkMode .page-link:hover {
    color: #fff;
}
body.darkMode .pagination li.paginate_button.page-item.active a {
    color: #fff !important;
}

body.darkMode .ant-picker-panel-container{
    background: #17212F;

}
body.darkMode .ant-picker-cell.ant-picker-cell-in-view .ant-picker-cell-inner {
    color:#fff !important;
}
body.darkMode .ant-picker-cell .ant-picker-cell-inner {
    color:#B7B7B7 !important;
}
body.darkMode .ant-picker-cell:hover .ant-picker-cell-inner {
    background: #1068CE !important;
}
body.darkMode .ant-picker-cell.ant-picker-cell-disabled .ant-picker-cell-inner {
    color: #17212F !important;
}
body.darkMode .ant-picker-cell-disabled::before {
    background-color: #17212F !important;
}
body.darkMode .ant-picker-header-view,
body.darkMode .ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner,
body.darkMode .ant-picker-panel-container button{
    color: #fff;
}
body.darkMode .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: #1E2938 !important;
}

body.darkMode .subjectList .active {
    background: #17212F !important;
}
