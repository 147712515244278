.logos img {
    max-height: 69px;
}
body footer .w350 {
    max-width: 350px;
}

body footer img {
    width: 200px;
    margin-left: 30px;
}
body footer .slo-sodstvo,
body footer .vsrs {
    max-width: 80px;
}
body footer .vsrs {
    height: 117px;
    max-height: 117px;
    margin-right: 20px;
}
body footer .slo-sodstvo {
    height: 100px;
    max-height: 80px;
    max-width: 150px;
    margin-top: 25px;
}
body footer .logo-pravosodje {
    max-height: 40px !important;
    margin-top: 15px;
}
body footer .eu-logo {
    margin-top: 11px;
    max-height: 46px;
}

.footer-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 1180px) {
    .footer-flex {
        flex-direction: column;
    }

    .footer-flex .logos {
        align-self: center;
    }
}