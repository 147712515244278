.button_wrappers {
    text-align: right;
}
.button_wrappers .btn {
    margin-right: 20px;
}

.flag_button {
    width: 20px;
    cursor: pointer;
    margin-left: 10px;
}
.label_flag {
    width: 30px;
    padding-right: 10px;
}
.translation_flags {
    text-align: right;
}

.mandatory {
    background: #ffb9b3;
}

.form-control.mandatory::-webkit-input-placeholder { /* Edge */
    color: red;
    opacity: 0.5;
}
.form-control.mandatory::-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: red;
    opacity: 0.5;
}
#subjects .mdi,
#debtors .mdi {
    font-size: 1.5rem;
}

.ant-picker.mandatory .ant-picker-input > input::placeholder,
.custom-select.mandatory,
.form-control.mandatory::placeholder{
    color: #ff6969;
}

#headingBail {
    cursor: pointer;
}
