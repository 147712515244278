.CookieConsent {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    bottom: 0;
    z-index: 1400;
    width: 100%;
    left: 0;
    padding: 20px;
}

.CookieCheckboxes {
    color: #fff;

}
.CookieCheckboxes label {
    font-weight: normal;
}

.cookie_wrapper {
    position: fixed;

    width: 100%;
    bottom: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.cookie_icon {
    display: block;
    width: 50%;
    max-width: 200px;
    margin: 0 auto;
}

@media only screen and (max-width: 992px) {
    .cookie_text {
        max-height: 200px;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .cookie_icon {
        width: 10%;
    }
}
