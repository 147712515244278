.user_location {
    max-width: 230px;
    padding-left: 15px;
    font-weight: bold;
}

body .publicSideMenu,
body .bidderSideMenu {
    min-width: 350px;
    max-width: 350px;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

body .publicSideMenu::-webkit-scrollbar,
body .bidderSideMenu::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}

.sideBarSpan {
    display: block !important;
    padding: .625rem 1.5rem;
    color: #707d8a;
    position: relative;
}

.sideBarIcon {
    display: inline-block;
    min-width: 1.75rem;
    padding-bottom: .125em;
    font-size: 1.2rem;
    line-height: 1.40625rem;
    vertical-align: middle;
    color: #707d8a;
}