.print_button {
    width: auto;
    display: inline-block;
    margin-left: 1.25rem;
    background: none;
    border: none;
}

@media print {
    #printme,
    #printme input,
    #printme textarea{
        font-size: 10px;
    }

    #phplive_btn_1598277422_clone {
        display: none;
    }

    .print_button {
        display: none;
    }

    .dontprint,
    .btn-primary, .btn-danger {
        display: none;
    }
}
