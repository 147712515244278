.errorCircle {
    background: red;
    border-radius: 50%;
    color: #fff;
    width: 22px;
    text-align: center;
    display: inline-block;
    position: absolute;
    top: -10px;
    right: -10px;
}

.nav-item {
    position: relative;
}
